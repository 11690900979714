<template>
<div>
  <h3 class="text-lg text-center my-8 font-bold leading-6 text-white">
    Summary Stats
  </h3>
  <div class="grid grid-cols-1 gap-5 sm:grid-cols-3">
    <div class="bg-gray-800 overflow-hidden shadow rounded-lg">
      <div class="px-4 py-5 sm:p-6">
        <dl>
          <dt class="text-sm leading-5 font-medium text-gray-300 truncate">
            Current Subscribers
          </dt>
          <dd class="mt-1 text-3xl leading-9 font-semibold text-white">
            {{ activeSubscribers.length }}
          </dd>
        </dl>
      </div>
    </div>
    <div class="bg-gray-800 overflow-hidden shadow rounded-lg">
      <div class="px-4 py-5 sm:p-6">
        <dl>
          <dt class="text-sm leading-5 font-medium text-gray-300 truncate">
            Subscribers Added in Past 7 Days
          </dt>
          <dd class="mt-1 text-3xl leading-9 font-semibold text-white">
            {{ addedSubscribers }}
          </dd>
        </dl>
      </div>
    </div>
    <div class="bg-gray-800 overflow-hidden shadow rounded-lg">
      <div class="px-4 py-5 sm:p-6">
        <dl>
          <dt class="text-sm leading-5 font-medium text-gray-300 truncate">
            Percent of Subscribers Active in Past 7 Days
          </dt>
          <dd class="mt-1 text-3xl leading-9 font-semibold text-white">
            {{ percentActiveSubscribers | decimals(0,0)}}%
          </dd>
        </dl>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import moment from 'moment'
export default {
  mounted () {
    var self = this
    this.editableUsers = this.editableUsers.map(function(user) {
      user.is_active = self.daysAgo(user.last_visited_at) < 7
      user.is_subscribed = user.end_date ? moment(user.end_date).isAfter(moment()) : true
      return user
    })
  },
  data () {
    return {
      editableUsers: JSON.parse(JSON.stringify(this.users))
    }
  },
  props: ['users'],
  methods: {
    daysAgo(datetime) {
      return moment().diff(moment(datetime), 'days')
    }
  },
  computed: {
    activeSubscribers() {
      // Not a part of The Tie team or Crypto101 team, and has an empty end_date or an end_date in the future. Stripe ID is only a requirement before user 8183
      return this.editableUsers.filter((u) => !(u.email.includes('crypto101podcast.com') || u.email.includes('cryptorevolution.com') || u.email.includes('thetie.io')) &&
        (u.stripe_id || u.id > 8183) && (u.end_date == null || moment(u.end_date).isAfter(moment())))
    },
    percentActiveSubscribers() {
      var self = this
      return this.activeSubscribers.filter(function(user) {
        return self.daysAgo(user.last_visited_at) < 7
      }).length / this.activeSubscribers.length * 100
    },
    addedSubscribers() {
      var self = this
      return this.users.filter(function(user) {
        return self.daysAgo(user.created_at) < 7 && !user.is_admin
      }).length
    }
  }
}
</script>